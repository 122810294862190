import { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import classNames from '../../../../Helper';
import { SelectorIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

function ProfileDropDown(props)
{
    return (
		<>
            <Menu as="div" className="px-3 mt-6 relative inline-block text-left">
                {({ open }) => (
                    <>
                    	<div>
                        	<Menu.Button className="group w-full bg-gray-100 rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-skin-primary">
                            	<span className="flex w-full justify-between items-center">
                                  	<span className="flex min-w-0 items-center justify-between space-x-3">
										<span className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-500 bg-gradient-to-r from-skin-hue to-blue-500">
        									<span className="text-xs font-medium leading-none text-white">{props.customer_name.charAt(0)}</span>
      									</span>
                                		<span className="flex-1 flex flex-col min-w-0">
                                	      	<span className="text-gray-900 text-sm font-medium truncate">{props.customer_name}</span>
                                    	</span>
                                	</span>
                              		<SelectorIcon
                                		className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                		aria-hidden="true"
                              		/>
                            	</span>
                            </Menu.Button>
                        </div>
                        <Transition
                          	show={open}
                          	as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                        	leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items
                                static
                                className="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
                            >
                            	<div className="py-1">
                                	<Menu.Item>
                                    	{({ active }) => (
                                      		<Link
                                        		to={`${process.env.PUBLIC_URL}/my-profile`}
                                        		className={classNames(
                                          			active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                          			'block px-4 py-2 text-sm'
                                        		)}
                                      		>
                                        		View profile
                                      		</Link>
                                    	)}
                                  	</Menu.Item>
                                  	<Menu.Item>
                                    	{({ active }) => (
                                    		<Link
                                      			to="#"
												onClick={() => props.updateLoginStatus(false)}
                                      			className={classNames(
                                        			active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        			'block px-4 py-2 text-sm'
                                      			)}
                                    		>
                                      			Sign out
                                    		</Link>
                                    	)}
                                  	</Menu.Item>
                                </div>
                            </Menu.Items>
                    	</Transition>
					</>
                )}
            </Menu> 
		</>
    );
}

export default ProfileDropDown;