import React, { Component, useState } from 'react';
import Axios from 'axios';
import PortalConfig from '../../../config';
import Feather from 'feather-icons';
import Cookies from 'js-cookie';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'
import Alert from '../../Alerts/Alert';
import { ChevronLeftIcon, ChevronDoubleLeftIcon, ChevronRightIcon, ChevronDoubleRightIcon } from '@heroicons/react/outline';

const Pristine = require('pristinejs');

class ListView extends Component
{
    state = {
        message: '',
        loading: false,
        filter_loading: false,
        filter_message: '',
        filter_success_message: '',
        sort_field: '',
        sort_order_by: '',
        headers: [],
        header_type: [],
        records: [],
        pagination_info: [],
        actions: [],
        user_filters: {},
        filter_info: {
            id: '',
            name: '',
        },
        field_data: [],
        filter_condition: [],
    };

    constructor(props) {
        super(props);
        this.hideErrorMessage = this.hideErrorMessage.bind(this);
        this.handleRedirect.bind();
        this.updateSorting = this.updateSorting.bind(this);
        this.createMarkup = this.createMarkup.bind(this);
    }

    // Set loading and message to null
    hideErrorMessage(event) {
        this.setState({
            loading: false,
            message: '',
            filter_loading: false,
            filter_message: '',
            filter_success_message: '',
        });    
    }

    createMarkup(html) {
        return { __html: html };
    }

    componentDidMount() {
        this.getRecords();
    }

    componentDidUpdate(prevProps, prevState) {
        
        if(prevProps.selected_filter !== this.props.selected_filter && !this.props.selected_filter) {
            this.getRecords();
        }

        if(prevProps.selected_module !== this.props.selected_module 
            || prevProps.page !== this.props.page
            || prevState.sort_field !== this.state.sort_field
            || prevState.sort_order_by !== this.state.sort_order_by
            || prevProps.advancedSearch != this.props.advancedSearch
            || prevProps.searchValue != this.props.searchValue
            ) {
            this.getRecords(true);
        }
    }

    /**
     * Handle redirect
     * 
     * @param {string} module 
     * @param {string} record_id 
     */
    handleRedirect(module, record_id) {
        this.props.history.push(process.env.PUBLIC_URL + '/' + module + '/detail/' + record_id);
    }

    /**
     * Update field sorting 
     * 
     * @param string field_name 
     */
    updateSorting(event) {
        let field_name = event.target.dataset.listField;
        let order_by = event.target.dataset.orderBy;

        let tmp_order_by = 'ASC';
        if(order_by == 'ASC') {
            tmp_order_by = 'DESC';
        }
        
        // Update the "order by" data 
        event.target.setAttribute('data-order-by', tmp_order_by);
        this.setState({
            sort_field: field_name,
            sort_order_by: order_by,
        });
    }

    /**
     * Get selected module record list from server
     */
    getRecords(search_flag = true) {
        // Show loading icon
        this.props.setLoading(true);
        
        const token = Cookies.get('ba_customer_portal_token');
        const config = {
            headers: {
                Authorization: 'bearer ' + token,
            }
        };

        var param_check = false;
        var endpoint_url = PortalConfig.crm_url + '/' + this.props.selected_module + '/page/' + this.props.page;
        if(this.props.searchValue && search_flag) {
            endpoint_url = endpoint_url + '?search_term=' + this.props.searchValue;
            param_check = true;
        }

        // Advanced search
        if(this.props.advancedSearch && search_flag) {
            let filter = '?filter=' + JSON.stringify(this.props.advancedSearch);
            if(param_check) {
                filter = '&filter=' + JSON.stringify(this.props.advancedSearch);
            }
            endpoint_url = endpoint_url + filter;
            param_check = true;
        }

        if(this.state.sort_field) {
            let sort_field = '?sort_field=';
            if(param_check) {
                sort_field = '&sort_field=';
            }
            endpoint_url = endpoint_url + sort_field + this.state.sort_field + '&field_order='+this.state.sort_order_by;  
            param_check = true;
        }

        if(this.props.parent_id) {
            let parent_field = '?parent_id=';
            if(param_check) {
                parent_field = '&parent_id=';
            }
            endpoint_url = endpoint_url + parent_field + this.props.parent_id;
        }
 
        Axios.get(endpoint_url, config).then((response) => {
            this.props.setLoading(false);
            if(response.data.status !== false) {
                this.setState({
                    headers: response.data.headers,
                    header_type: response.data.header_type,
                    records: response.data.records,
                    pagination_info: response.data.pagination_info,
                    search_fields: response.data.filter_fields,
                    sort_field: response.data.sort_field,
                    sort_order_by: response.data.sort_order_by,
                    actions: response.data.actions,
                    user_filters: response.data.user_filters,
                });
                Feather.replace();
            }
            else {
                this.setState({
                    message: response.data.message,
                    headers: [],
                    records: [],
                    pagination_info: [],
                    actions: [],
                    search_fields: [],
                });    
            }
        }).catch((error) => {
            this.props.setLoading(true);
            this.setState({
                message: error.response.data.message,
            });
            
            if (error.message == 'Request failed with status code 401') {
                this.props.updateLoginStatus(false);
            }
        });
    }

    render() {
        return (
            <div>
                {this.state.message ? <div className="pb-2"> <Alert message={this.state.message} type="danger" hideAlert={this.hideErrorMessage} /> </div> : ""}

                <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            {Object.keys(this.state.headers).map((field_name) => {
                                                let sort_icon = <FontAwesomeIcon icon={faSort} />;
                                                let order_by = 'ASC';
                                                if(this.state.sort_field == field_name && this.state.sort_order_by == 'ASC') {
                                                    sort_icon = <FontAwesomeIcon icon={faSortUp} />;
                                                    order_by = 'DESC';
                                                }
                                                else if(this.state.sort_field == field_name && this.state.sort_order_by == 'DESC') {
                                                    sort_icon = <FontAwesomeIcon icon={faSortDown} />;
                                                }

                                                return <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" key={field_name}><span data-list-field={field_name} data-order-by={order_by} style={{ color: 'inherit', cursor: 'pointer' }} onClick={(e) => this.updateSorting(e)} > {this.state.headers[field_name]} {sort_icon} </span> </th>
                                            })}
                                            <th> </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.records.map((record, index) => {
                                            return (
                                                <tr key={record.id} className={index % 2 === 0 ? 'bg-white group' : 'bg-gray-50 group'}>
                                                    {Object.keys(this.state.headers).map((field_name) => {
                                                        const tableData = this.state.header_type[field_name] == 'text' ? 
                                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-skin-base" onClick={() => this.handleRedirect(this.props.selected_module, record.id)} key={field_name}>
                                                                {record[field_name] != 'null' ? 
                                                                    <div dangerouslySetInnerHTML={this.createMarkup(record[field_name])}></div>
                                                                : ''}
                                                            </td> 
                                                        :
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-skin-base" onClick={() => this.handleRedirect(this.props.selected_module, record.id)} key={field_name}>{record[field_name]}</td>;

                                                        return (
                                                            tableData
                                                        );
                                                    })}

                                                    <td width="6%"> 
                                                        <div className=""> 
                                                            {this.state.actions && this.state.actions.includes('create') ? <Link className="block md:hidden group-hover:block" to={`${process.env.PUBLIC_URL}/${this.props.selected_module}/edit/${record.id}`}> <span data-feather="edit-2"></span> </Link> : ''}
                                                            {this.state.actions && this.state.actions.includes('delete') ? <span className="block md:hidden group-hover:block" data-feather="trash-2"></span> : ''}
                                                        </div> 
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.records.length == 0 ? <div className="pt-4"> <Alert message="No records to show" type="info" hideClose={true} /> </div> : ""}

                {this.state.records.length > 0 ?
                <div className="flex justify-center">
                    <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px pt-6" aria-label="Pagination">
                        <Link
                            to={this.state.pagination_info.first_page != -1 ? `${process.env.PUBLIC_URL}/${this.props.selected_module}/page/${this.state.pagination_info.first_page}` : '#'} 
                            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                                <span className="sr-only">First</span>
                                <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
                        </Link>
                        <Link
                            to={this.state.pagination_info.previous_page != -1 ? `${process.env.PUBLIC_URL}/${this.props.selected_module}/page/${this.state.pagination_info.previous_page}` : '#'} 
                            className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                                <span className="sr-only">Previous</span>
                                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                        </Link>
                        <div className="sm:flex-1 sm:flex sm:items-center sm:justify-between">
                            <div className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                                <p className="text-sm text-gray-700">
                                    <span className="font-medium">{this.state.pagination_info.start} - {this.state.pagination_info.end} of {this.state.pagination_info.total_records}</span>
                                </p>
                            </div>
                        </div>
                        <Link
                            to={this.state.pagination_info.next_page != -1 ? `${process.env.PUBLIC_URL}/${this.props.selected_module}/page/${this.state.pagination_info.next_page}` : '#'} 
                            className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                                <span className="sr-only">Next</span>
                                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                        </Link>
                        <Link
                            to={this.state.pagination_info.last_page != -1 ? `${process.env.PUBLIC_URL}/${this.props.selected_module}/page/${this.state.pagination_info.last_page}` : '#'} 
                            className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                                <span className="sr-only">Last</span>
                                <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
                        </Link>
                    </nav>
                </div> : ''}
            </div>
        );
    }
}

export default ListView;
