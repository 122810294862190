import React from 'react';

function LineItems(props)
{
    return (
        <div>
            <div className="row">
                <div className="col"> 
                    <h5 className="text-muted">Line Items</h5> 
                </div>
            </div>
            <hr style={{marginTop: '0.5rem'}} />
            {props.groups.map((group_info) => {
                if(group_info.line_items.length <= 0) {
                    return <></>;
                }

                let GroupLineItems = group_info.line_items.map((line_item) => {
                    return (
                        <tr>
                            <td>{line_item.name}</td>
                            <td>{line_item.quantity}</td>
                            <td>{line_item.product_unit_price}</td>
                            <td>{line_item.vat}</td>
                            <td>{line_item.vat_amount}</td>
                            <td>{line_item.total_price}</td>
                        </tr>
                    )
                });

                return (
                    <>
                        <h5>{group_info.name}</h5>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Product name</th>
                                    <th>Quantity</th>
                                    <th>Price</th>
                                    <th>Tax</th>
                                    <th>Tax amount</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {GroupLineItems}
                            </tbody>
                        </table>
                        <div className='mb-3'>
                            <div className='row'> 
                                <div className='col-md-2 font-weight-light text-secondary'>Total</div>
                                <div className='col-md-2 font-weight-normal text-dark'>{group_info.total}</div>
                            </div>
                            <div className='row'>
                                <div className='col-md-2 font-weight-light text-secondary'>Discount</div>
                                <div className='col-md-2 font-weight-normal text-dark'>{group_info.discount}</div>
                            </div>
                            <div className='row'>
                                <div className='col-md-2 font-weight-light text-secondary'>Subtotal</div>
                                <div className='col-md-2 font-weight-normal text-dark'>{group_info.subtotal}</div>
                            </div>
                            <div className='row'>
                                <div className='col-md-2 font-weight-light text-secondary'>Tax</div>
                                <div className='col-md-2 font-weight-normal text-dark'>{group_info.tax}</div>
                            </div>
                            <div className='row'>
                                <div className='col-md-2 font-weight-light text-secondary'>Grand total</div>
                                <div className='col-md-2 font-weight-normal text-dark'>{group_info.grand_total}</div>
                            </div>
                        </div>
                    </>
                );
            })}
        </div>
    );
}

export default LineItems;