import Axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PortalConfig from "../../../../config";
import AdvancedSearch from "./Search";

function ContainerHead(props)
{
    const [searchState, setSearchState] = useState(false);
    const [userFilters, setUserFilters] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('');
    const [filterFields, setFilterFields] = useState([]);
    const [filterInfo, setFilterInfo] = useState({
        id: '',
        name: '',
    });

    const [actions, setActions] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [createUrl, setCreateUrl] = useState('');
    const [labels, setLabels] = useState([]);
    const [showCreateButton, setShowCreateButton] = useState(true);

    useEffect(() => {

        // No need to show create button in Project Task module for now.
        // We are showing the create button in projects detail view
        if(props.selected_module == 'ProjectTask') {
            setShowCreateButton(false);
        }
        
        setCreateUrl(`${process.env.PUBLIC_URL}/${props.selected_module}/create`);
        if(props.parent_id) {
            setCreateUrl(createUrl + '?parent_id=' + props.parent_id);
            setShowCreateButton(true);
        }

        if(props.currentView == 'List') {
            // Get filters
            const token = Cookies.get('ba_customer_portal_token');
            if(props.selected_module) {
                let endpoint_url = PortalConfig.crm_url + '/' + props.selected_module + '/filters';
                const config = {
                    headers: {
                        Authorization: 'bearer ' + token,
                    }
                };
            
                props.setLoading(true);
                Axios.get(endpoint_url, config).then((response) => {
                    setActions(response.data.actions);
                    setUserFilters(response.data.user_filters);
                    setFilterFields(response.data.filter_fields);
                    setLabels(response.data.labels);
                    props.setLoading(false);
                });
            }

            let cachedFilter = Cookies.get('ba_customer_portal_' + props.selected_module + '_filter');
            if(cachedFilter) {
                setSelectedFilter(cachedFilter);
            }
            else {
                setSelectedFilter('');
                // Reset the search whenever module changes
                props.setAdvancedSearch([{
                    field_name: '',
                    field_condition: 'equal',
                    field_value: '',
                }]);
            }

            setSearchValue('');
        }

    }, [props.selected_module]);

    /**
     * Get filter information
     */
    useEffect(() => {

        if(selectedFilter != '' && props.selected_module && props.currentView == 'List') {
            const token = Cookies.get('ba_customer_portal_token');
            var endpoint_url = PortalConfig.crm_url + '/' + props.selected_module + '/filter/' + selectedFilter;
            const config = {
                headers: {
                    Authorization: 'bearer ' + token,
                }
            };
    
            props.setLoading(true);
            Axios.get(endpoint_url, config).then((response) => {
                props.setAdvancedSearch(response.data.advancedSearch);
                props.setLoading(false);
            }).catch((error) => {
                props.setLoading(false);
                if (error.message == 'Request failed with status code 401') {
                    props.updateLoginStatus(false);
                }
            });
        }
        else {
            props.setAdvancedSearch([{
                field_name: '',
                field_condition: 'equal',
                field_value: '',
            }]);
        }

    }, [selectedFilter]);

    /**
     * Apply selected filter
     * 
     * @param {object} event 
     */
    function applyFilter(event) {
        let value = event.target.value;
        let selected_index = event.target.selectedIndex;
        let option_text = event.target[selected_index].text;
        if(value) {
            // Update the filter id
            setSelectedFilter(value);
            setFilterInfo({
                id: value,
                name: option_text,
            });

            props.setSelectedFilter(value);
        }
        else {
            setSelectedFilter('');
            props.setAdvancedSearch([{
                field_name: '',
                field_condition: 'equal',
                field_value: '',
            }]);

            setFilterInfo({
                id: '',
                name: '',
            });

            props.setSelectedFilter('');
        }

        // Store the filter in cookie
        Cookies.set('ba_customer_portal_' + props.selected_module + '_filter', value);
    }

    // On key press
    function onKeyPress(event) {
        // If user hit enter from Search input
        if(event.keyCode === 13) {
            props.setSearchValue(event.target.value);
        }
    }

    return (
        <>
        <AdvancedSearch 
            selected_module={props.selected_module} 
            search_fields={filterFields} 
            advancedSearch={props.advancedSearch} 
            setAdvancedSearch={props.setAdvancedSearch} 
            searchState={searchState} 
            setSearchState={setSearchState} 
            updateLoginStatus={props.updateLoginStatus}
            setUserFilters={setUserFilters}
            filterInfo={filterInfo}
            setFilterInfo={setFilterInfo}
            setSelectedFilter={setSelectedFilter}
        />

        <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 gap-4">
            <div className="flex-1 min-w-0">
                <div className="flex">
                    <div>
                        <h1 className="text-lg font-medium leading-6 text-gray-900 sm:truncate">{props.selected_module ? props.selected_module : 'Dashboard'}</h1>
                    </div>
                    <div>
                        {props.loading ? 
                            <svg className="text-skin-primary-darker animate-spin ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        : ''}
                    </div>
                </div>
            </div>
            {props.selected_module && props.currentView == 'List' ? 
                <>
                <div>
                    <select name="filters" onChange={applyFilter} value={selectedFilter} className="border rounded-md border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-skin-primary focus:border-skin-primary sm:text-sm">
                        <option value=''>Select your filter</option>
                        {Object.keys(userFilters).map((index) => {
                            return <option key={index} value={index}>{userFilters[index]}</option>
                        })}
                    </select>
                </div>
                <div>
                    <input className="border rounded-md border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-skin-primary focus:border-skin-primary sm:text-sm" onKeyDown={onKeyPress} type="text" name="search" placeholder="Search" aria-label="Search" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                </div>
                <div>
                    <svg onClick={() => setSearchState(true)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z" />
</svg>
                </div>
                </>
            : ''}
            {actions && actions.includes('create') && props.currentView == 'List' ? 
                <Link className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-skin-muted bg-skin-primary-dark hover:bg-skin-primary-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-primary sm:order-1 sm:ml-3" to={`${createUrl}`}>{labels && labels.create ? labels.create : 'Create'}</Link>
            : ''}
        </div>
        </>
    )
}

export default ContainerHead;