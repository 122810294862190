const custom_config = {
    crm_url: 'https://client.sokrates.solutions',
//    crm_url: 'https://demo.blackant.io/suitecrm/portal/v1.8',

};

const portal_config = {
    crm_url: custom_config.crm_url + '/ba_api',
    portal_url: 'https://portal.sokrates.solutions',
    layout: 'main',
};

export default portal_config;
