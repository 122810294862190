import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './Date.css';
import trix from 'trix';
import "trix/dist/trix.css";
import { parseISO } from 'date-fns';

class FormElement extends Component 
{
    constructor(props) {
        super(props);
        this.trixInput = React.createRef();
    }

    componentDidMount() {
        if(this.props.module == 'Cases' && this.props.field_name == 'description') {
            this.trixInput.current.addEventListener("trix-change", event => {
                this.props.handleChange(event);
            });   
        }
    }

    onDateTimeChange(date_value) {
        if(date_value) {
            date_value = date_value.getFullYear() + '-' + ('0' + (date_value.getMonth() + 1)).slice(-2) + '-' + ('0' + date_value.getDate()).slice(-2) + ' ' + ('0' + date_value.getHours()).slice(-2) + ':' + ('0' + date_value.getMinutes()).slice(-2) + ':00';
        }
        this.props.handleDateChange(this.props.field_name, date_value);
    }

    onDateChange(date_value) {
        if(date_value) {
            date_value = date_value.getFullYear() + '-' + ('0' + (date_value.getMonth() + 1)).slice(-2) + '-' + ('0' + date_value.getDate()).slice(-2);
        }
        this.props.handleDateChange(this.props.field_name, date_value);
    }

    render() {

        let previewImage = {
            border: '1px solid #ddd',
            borderRadius : '4px',
            padding: '5px',
            width: '150px',
            marginTop:'1rem'
        }

        let required = ''; 
        if(this.props.more_info.required == true || this.props.more_info.required == 'true') {
            required = 'data-pristine-required';
        }

        let date_format = this.props.date_format;
        date_format = date_format.replace('m', 'MM');
        date_format = date_format.replace('d', 'dd');

        let element;
        let error_class = this.props.errors[this.props.field_name] == true ? 'is-invalid' : '';

        switch(this.props.type) {   
            case "text":
                element = <input data-pristine-required={required} type="text" className={`mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-skin-primary focus:border-skin-primary sm:text-sm ${error_class}`} id={this.props.field_name} name={this.props.field_name} value={this.props.field_value != 'null' ? this.props.field_value : ''} onChange={this.props.handleChange} />;
                break;
            case "checkbox":
                element = <input type="checkbox" defaultChecked={this.props.field_value == '1' ? true : false} className={`focus:ring-skin-primary h-4 w-4 text-skin-primary-dark border-gray-300 rounded ${error_class}`} id={this.props.field_name} name={this.props.field_name} value={this.props.field_value} onChange={this.props.handleChange} />
                break;
            case "select":
                let select_options = '';
                if(this.props.more_info.options) {
                    select_options = Object.keys(this.props.more_info.options).map((option_key, index) => {
                                        if(index == 0) {
                                            return (
                                                <>
                                                <option value='' key="none">Select</option>
                                                <option value={option_key} key={option_key}>{this.props.more_info.options[option_key]}</option>
                                                </>
                                            )
                                        }
                                        return (
                                            <option value={option_key} key={option_key}>{this.props.more_info.options[option_key]}</option>
                                        );
                                    });
                };
                element = <select data-pristine-required={required} name={this.props.field_name} id={this.props.field_name} value={this.props.field_value} className={`mt-1 border rounded-md w-full border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-skin-primary focus:border-skin-primary sm:text-sm ${error_class}`} onChange={this.props.handleChange}> {select_options} </select>;
                break;    
            case "textarea":
                element = this.props.module == 'Cases' && this.props.field_name == 'description' ? 
                <> <input id={this.props.field_name} type="hidden" onChange={this.props.handleChange} name={this.props.field_name} value={this.props.field_value != 'null' ? this.props.field_value : ''} /> <trix-editor input={this.props.field_name} ref={this.trixInput}></trix-editor> </>
                :
                <textarea data-pristine-required={required} rows="3" type="text" className={`mt-1 max-w-lg shadow-sm block w-full focus:ring-skin-primary focus:border-skin-primary sm:text-sm border border-gray-300 rounded-md ${error_class}`} id={this.props.field_name} name={this.props.field_name} value={this.props.field_value != 'null' ? this.props.field_value : ''} onChange={this.props.handleChange} />
                break;    
            case "image":
                let input_element = <input type="file" className='form-control-file' id={this.props.field_name}  name={this.props.field_name} onChange={this.props.handleChange} accept="image/*" />;
                let preview_image = this.props.field_value ? <div>{this.props.field_value}</div> : '';
                if(error_class) { 
                    element = [input_element, <small className="text-red-600 form-text">{this.props.field_label} field is mandatory</small>];
                }
                else {
                    element = [input_element, preview_image];
                }
                break;    
            case "file":
                input_element = <input type="file" ref={this.props.fileInput} className='form-control-file' id={this.props.field_name} name={this.props.field_name} onChange={this.props.handleChange} />;
                if(error_class) {
                    element = [input_element, <small className="text-red-600 form-text">{this.props.field_label} field is mandatory</small>];
                }
                else {
                    element = [input_element, <p>{this.props.field_value}</p>];
                }
                break;
            case "datetime":
                let date_time_value = '';
                if(this.props.field_value) {
                    date_time_value = new Date(parseISO(this.props.field_value));
                }

                element = <DatePicker data-pristine-required={required} showTimeSelect selected={date_time_value} dateFormat={`${date_format} hh:mm:ss`} timeFormat="HH:mm" timeIntervals={15} timeCaption="time" className={`mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-skin-primary focus:border-skin-primary sm:text-sm ${error_class}`} id={this.props.field_name} name={this.props.field_name} onChange={this.onDateTimeChange.bind(this)} />;
                break;
            case "date":
                let date_value = '';
                if(this.props.field_value) {
                    date_value = new Date(parseISO(this.props.field_value));
                }
                
                // yyyy-MM-dd
                element = <DatePicker data-pristine-required={required} selected={date_value} dateFormat={date_format} className={`mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-skin-primary focus:border-skin-primary sm:text-sm ${error_class}`} id={this.props.field_name} name={this.props.field_name} onChange={this.onDateChange.bind(this)} />;
                break;
            default:
                element = <input data-pristine-required={required} type="text" className={`mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-skin-primary focus:border-skin-primary sm:text-sm ${error_class}`} id={this.props.field_name} name={this.props.field_name} value={this.props.field_value} onChange={this.props.handleChange} />;
                break;
        }
        return element;
    }
}

export default FormElement;