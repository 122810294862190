import React, { Component } from 'react';
import Navigation from './Navigation/Navigation';
import Sidebar from './Sidebar/Sidebar';
import ListView from './Views/ListView';
import DetailView from './Views/DetailView';
import EditView from './Views/EditView';
import Dashboard from './Views/Dashboard';
import './Portal.css';
import { Redirect } from "react-router-dom";
import Feather from 'feather-icons';
import PortalConfig from '../../config';
import MainLayout from './Layouts/Main/Index';

class Portal extends Component
{
    state = {
        loading: false,
        searchValue: '',
        selected_filter: '',
        advancedSearch: [{
            field_name: '',
            field_condition: 'equal',
            field_value: '',
        }],
    }

    constructor(props) {
        super(props);
        this.setLoading = this.setLoading.bind(this);
        this.setSelectedFilter = this.setSelectedFilter.bind(this);
        this.setAdvancedSearch = this.setAdvancedSearch.bind(this);
        this.setSearchValue = this.setSearchValue.bind(this);
    }

    // Set loading state
    setLoading(state) {
        if(state != this.state.loading) {
            this.setState({
                loading: state
            });
        }
    }

    /**
     * Set filter
     * 
     * @param {string} filter 
     */
    setSelectedFilter(filter) {
        this.setState({
            selected_filter: filter,
        }); 
    }

    /**
     * Set advanced search
     * 
     * @param {object} advancedSearch 
     */
    setAdvancedSearch(advancedSearch) {
        this.setState({
            advancedSearch: advancedSearch,
        });
    }

    /**
     * Set search value
     * 
     * @param {string} value 
     */
    setSearchValue(value) {
        this.setState({
            searchValue: value,
        });
    }

    componentDidMount() {
        Feather.replace();
    }

    render() {  
        if(this.props.logged_in === false) {
            return <Redirect to={`${process.env.PUBLIC_URL}/login`} />;
        } 

        let View;
        if(this.props.view === 'List') {
            View = <ListView 
                setLoading={this.setLoading}
                history={this.props.history} 
                updateLoginStatus={this.props.updateLoginStatus} 
                selected_module={this.props.selected_module} 
                page={this.props.page} 
                moduleLabel={this.props.module_labels[this.props.selected_module]}
                advancedSearch={this.state.advancedSearch}
                selected_filter={this.state.selected_filter}
                searchValue={this.state.searchValue}
            />;
        }
        else if(this.props.view === 'Detail') {
            View = <DetailView 
                history={this.props.history} 
                updateLoginStatus={this.props.updateLoginStatus} 
                selected_module={this.props.selected_module} 
                record_id={this.props.record_id}
                moduleLabel={this.props.module_labels[this.props.selected_module]}
            />;
        }
        else if(this.props.view === 'Edit') {
            View = <EditView 
                history={this.props.history} 
                updateLoginStatus={this.props.updateLoginStatus} 
                selected_module={this.props.selected_module} 
                record_id={this.props.record_id}
                moduleLabel={this.props.module_labels[this.props.selected_module]}
            />;
        }
        else if(this.props.view === 'dashboard') {
            View = <Dashboard 
                history={this.props.history} 
                updateLoginStatus={this.props.updateLoginStatus} 
            />;
        }

        if(PortalConfig.layout == 'main') {
            return (
                <div className="h-screen flex overflow-hidden bg-gray-100">
                    <MainLayout 
                        record_id={this.props.record_id}
                        currentView={this.props.view}
                        customer_name={this.props.customer_name}
                        loading={this.state.loading}
                        setLoading={this.setLoading}
                        setSearchValue={this.setSearchValue}
                        setSelectedFilter={this.setSelectedFilter} 
                        selected_module={this.props.selected_module} 
                        modules={this.props.modules} 
                        moduleLabels={this.props.module_labels} 
                        view={View} 
                        updateLoginStatus={this.props.updateLoginStatus} 
                        advancedSearch={this.state.advancedSearch}
                        setAdvancedSearch={this.setAdvancedSearch}
                    />
                </div>
            );
        }
        else {
            return (
                <div>
                    <Navigation updateLoginStatus={this.props.updateLoginStatus} customer_name={this.props.customer_name} />
                    <div className="container-fluid">
                        <Sidebar modules={this.props.modules} moduleLabels={this.props.module_labels} />
                        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4">
                            {View}
                        </main>
                    </div>
                </div>
            );
        }
    }
}

export default Portal;
