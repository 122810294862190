import { Link } from "react-router-dom";
import classNames from '../../../../Helper';

function Nav(props)
{
    return (
        <nav className="px-3 mt-6">
            <div className="space-y-1">
                {props.navigation.map((item) => (
                    <Link
                        key={item.name}
                        to={item.href}
                        onClick={() => props.updateCurrentNav(item.name)}
                        className={classNames(
                            item.current ? 'bg-gray-200 text-gray-900' : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50',
                            'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                    >
                        <item.icon
                            className={classNames(
                                item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                'mr-3 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                        />
                        {item.name}
                    </Link>
                ))}
            </div>
      </nav>
    )
}

export default Nav;